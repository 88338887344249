import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCHDBrPdJAQEdwv4f8zodQy4LUjV6Dmtzg",
  authDomain: "the-ecosystem-f2b6a.firebaseapp.com",
  projectId: "the-ecosystem-f2b6a",
  storageBucket: "the-ecosystem-f2b6a.appspot.com",
  messagingSenderId: "932204966111",
  appId: "1:932204966111:web:2a1fc70857f6e8938c396a"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);